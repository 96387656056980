const initialState = {
  isFetchingRecivableAccount:false,
  isFetchingLevel3Account:false,
  recivableAccountList:[],
  level3AccountList:[],
  isSavingRecivableAccount:false,
  isSavingLevel3Account:false,
  recivableAccount:[],
  level3Account:[],
  isFetchingAccountMasterControlList:false,
  isSavingAccountMasterControlList:false,
  accountMasterControl:[],
  accountMasterControlList:[],
  warehouseList: [],
  isAddingWareHouse: false,
  dataAddedWarehouse: false,
  dataNotAddedWarehouse: false,
  categoryList: [],
  isAddingCategory: false,
  dataAddedCategory: false,
  dataNotAddedCategory: false,
  subCategoryList: [],
  isAddingSubCategory: false,
  dataAddedSubCategory: false,
  dataNotAddedSubCategory: false,
  isFetchingwarehouseList: false,
  warehouse: [],
  category: [],
  subCategory: [],
  isFetchingWareHousedata: false,
  inventory: [],
  isFetchingInventorydata: false,
  isAddingInventory: false,
  dataAddedInventory: false,
  dataNotAddedInventory: false,
  inventoryList: [],
  isFetchingInventoryGetdata: false,
  demand: [],
  isAddingDemanddata: false,
  notAddDemandData: false,
  attributeById: [],
  demandList: [],
  isFetchingGetAttributeById: false,
  allInventory: [],
  isFetchingInventoryAll: false,
  isFetchingDemand: false,
  purchaseList1: [],
  isFetchingPurchaseList: false,
  porductListDemand: [],
  isFetchingProductByDemand: false,
  addPurchase: [],
  approveDemandList: [],
  isFetchningApproveDemandList: false,
  attributeList: [],
  dataNotAttribute: false,
  dataAddedAttribute: false,
  isFetchingUploadFile: false,
  uploadfile: [],
  addUploadFile: false,
  notAdduploadFile: false,
  subCategoryListAdd: [],
  isAddingPurchase: false,
  notAddPurchase: false,
  approvePurchaseList: [],
  isApprovePurchaseListFetching: false,
  purchaseListById: [],
  isFetchingPurchaseById: false,
  IGP: [],
  isAddingIGP: false,
  IGPList: [],
  isFetchingIGP: false,
  issuance: [],
  isAddingIssuance: false,
  isFetchingIssuance: false,
  IssuanceList: [],
  searchInventoryList: [],
  isFetchingSearchInventoryList: false,
  isFetchingSearchDemandList: false,
  searchDemandList: [],
  isFetchingSearchPurchaseList: false,
  searchPurchaseList: [],
  searchIGPList: [],
  isFetchingSearchIGPList: false,
  categoryByWareHouseValue: [],
  isFetchingCategoryByWareHouse: false,
  subCategoryByCategoryValue: [],
  isFetchingSubCategoryByCategory: false,

  productByVendorList:[],
  isFetchingProductByVendor:false,

  isFetchingPurchaseFromDemand: false,
  purchaseFromDemandData: [],
  isFetchingSearchIssuanceList: false,
  searchIssuanceList: [],
  isFetchningApproveDemandListNew: false,
  approveDemandListNew: [],
  isFetchingPurchaseFromDemandNew: false,
  purchaseFromDemandDataNew: [],
  ReceivingList: [],
  isFetchingReceiving: false,
  receiving: [],
  isAddingReceiving: false,
  isFetchingSearchReceivingList: false,
  searchReceivingList: [],
  isAddingMasterControl: false,
  masterControl: [],
  isFetchingmasterControlList: false,
  masterControlList: [],
  isAddingInvMasterControl:false,

  isFetchingInvMasterControl:false,
  invMasterControlList:[],
  isFetchingInvModal:false,
  invModalList:[],
  isFetchingInvSize:false,
  invSizeList:[],
  isFetchingReceivingById:false,
  receivingById:[],
  isFetchingReturn:false,
  returnList:[]
};

const AddDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_WAREHOUSE":
      return {
        ...state,
        isAddingWareHouse: true,
        dataAddedWarehouse: false,
        dataNotAddedWarehouse: false,
      };
    case "SUCCESS_ADD_WAREHOUSE":
      return {
        ...state,
        warehouse: action.payload,
        isAddingWareHouse: false,
        dataAddedWarehouse: true,
        dataNotAddedWarehouse: false,
      };
    case "ERROR_ADD_WAREHOUSE":
      return {
        ...state,
        isAddingWareHouse: false,
        dataAddedWarehouse: false,
        dataNotAddedWarehouse: true,
      };
    //master cotrol
    case "REQUEST_ADD_MASTER_CONTROL":
      return { ...state, isAddingMasterControl: true };
    case "SUCCESS_ADD_MASTER_CONTROL":
      return {
        ...state,
        masterControl: action.payload,
        isAddingMasterControl: false,
      };
    case "ERROR_ADD_MASTER_CONTROL":
      return { ...state, isAddingMasterControl: false };

    case "REQUEST_CATEGORY_BY_WAREHOUSE":
      return { ...state, isFetchingCategoryByWareHouse: true };
    case "SUCCESS_CATEGORY_BY_WAREHOUSE":
      return {
        ...state,
        categoryByWareHouseValue: action.payload,
        isFetchingCategoryByWareHouse: false,
      };
    case "ERROR_CATEGORY_BY_WAREHOUSE":
      return { ...state, isFetchingCategoryByWareHouse: false };

    case "REQUEST_SUB_CATEGORY_BY_cATEGORY":
      return { ...state, isFetchingSubCategoryByCategory: true };
    case "SUCCESS_SUB_CATEGORY_BY_cATEGORY":
      return {
        ...state,
        subCategoryByCategoryValue: action.payload,
        isFetchingSubCategoryByCategory: false,
      };
    case "ERROR_SUB_CATEGORY_BY_cATEGORY":
      return { ...state, isFetchingSubCategoryByCategory: false };

    case "REQUEST_WARE_HOUSE_DATA":
      return {
        ...state,
        isFetchingwareHouseList: true,
        isState: false,
        dataNotAddedWarehouse: false,
      };
    case "SUCCESS_WARE_HOUSE_GET_DATA":
      return {
        ...state,
        warehouseList: action.payload,
        isFetchingwareHouseList: false,
        isState: true,
        dataNotAddedWarehouse: false,
      };
    case "ERROR_WARE_HOUSE_GET_DATA":
      return { ...state, isFetchingwareHouseList: false, isState: false };
    //get master
    case "REQUEST_GET_MASTER_CONTROL":
      return { ...state, isFetchingmasterControlList: true };
    case "SUCCESS_GET_MASTER_CONTROL":
      return {
        ...state,
        masterControlList: action.payload,
        isFetchingmasterControlList: false,
      };
    case "ERROR_GET_MASTER_CONTROL":
      return { ...state, isFetchingmasterControlList: false };

    case "DELETE_REQUEST_USERS":
      return { ...state };
    case "DELETE_SUCCESS_USERS":
      return {
        ...state,
        warehouseList: state.warehouseList.filter(
          (item) => item.wareHouseId !== action.wareHouseId
        ),
      };
    case "DELETE_ERROR_USERS":
      return { ...state };
    case "UPDATE_REQUEST_USERS":
      return { ...state };
    case "UPDATE_SUCCESS_USERS":
      return {
        ...state,
        warehouse: state.warehouse.map(
          (x) =>
            action.payload.find(
              ({ wareHouseId }) => wareHouseId === x.wareHouseId
            ) || x
        ),
      };
    case "UPDATE_ERROR_USERS":
      return { ...state };
    case "RESET_REDUCER":
      return {
        ...state,
        dataAddedWarehouse: false,
        dataAddedCategory: false,
        dataAddedSubCategory: false,
        dataAddedAttribute: false,
        dataAddedInventory: false,
      };

    //category
    case "REQUEST_ADD_CATEGORY":
      return {
        ...state,
        isAddingCategory: true,
        dataAddedCategory: false,
        dataNotAddedCategory: false,
      };
    case "SUCCESS_ADD_CATEGORY":
      return {
        ...state,
        category: action.payload,
        isAddingCategory: false,
        dataAddedCategory: true,
        dataNotAddedCategory: false,
      };
    case "ERROR_ADD_CATEGORY":
      return {
        ...state,
        isAddingCategory: false,
        dataAddedCategory: false,
        dataNotAddedCategory: true,
      };

    case "REQUEST_GET_ALL_CATEGORY_DATA":
      return {
        ...state,
        isFetchingcategoryList: true,
        isState: false,
        dataNotAddedCategory: false,
      };
    case "SUCCESS_GET_ALL_CATEGORY_DATA":
      return {
        ...state,
        categoryList: action.payload.data,
        isFetchingcategoryList: false,
        isState: true,
        dataNotAddedCategory: false,
      };
    case "ERROR_GET_ALL_CATEGORY_DATA":
      return { ...state, isFetchingcategoryList: false, isState: false };

    case "DELETE_REQUEST_USERS":
      return { ...state };
    case "DELETE_SUCCESS_USERS":
      return {
        ...state,
        categoryList: state.categoryList.filter(
          (item) => item.categoryId !== action.categoryId
        ),
      };
    case "DELETE_ERROR_USERS":
      return { ...state };
    case "UPDATE_REQUEST_USERS":
      return { ...state };
    case "UPDATE_SUCCESS_USERS":
      return {
        ...state,
        category: state.category.map(
          (x) =>
            action.payload.find(
              ({ categoryId }) => categoryId === x.categoryId
            ) || x
        ),
      };
    case "UPDATE_ERROR_USERS":
      return { ...state };
    //SUB CATEGORY
    case "REQUEST_ADD_SUB_CATEGORY":
      return {
        ...state,
        isAddingSubCategory: true,
        dataAddedSubCategory: false,
        dataNotAddedSubCategory: false,
      };
    case "SUCCESS_ADD_SUB_CATEGORY":
      return {
        ...state,
        subCategoryListAdd: action.payload,
        isAddingSubCategory: false,
        dataAddedSubCategory: true,
        dataNotAddedSubCategory: false,
      };
    case "ERROR_ADD_SUB_CATEGORY":
      return {
        ...state,
        isAddingSubCategory: false,
        dataAddedSubCategory: false,
        dataNotAddedSubCategory: true,
      };

    case "REQUEST_GET_ALL_SUB_CATEGORY_DATA":
      return {
        ...state,
        isFetchingsubCategoryList: true,
        isState: false,
        dataNotAddedSubCategory: false,
      };
    case "SUCCESS_GET_ALL_SUB_CATEGORY_DATA":
      return {
        ...state,
        subCategoryList: action.payload.data,
        isFetchingsubCategoryList: false,
        isState: true,
        dataNotAddedSubCategory: false,
      };
    case "ERROR_GET_ALL_SUB_CATEGORY_DATA":
      return { ...state, isFetchingsubCategoryList: false, isState: false };

    case "DELETE_REQUEST_USERS":
      return { ...state };
    case "DELETE_SUCCESS_USERS":
      return {
        ...state,
        subCategoryList: state.subCategoryList.filter(
          (item) => item.subCategoryId !== action.subCategoryId
        ),
      };
    case "DELETE_ERROR_USERS":
      return { ...state };

    //Inventory
    case "REQUEST_ADD_INVENTORY":
      return {
        ...state,
        isAddingInventory: true,
        dataAddedInventory: false,
        dataNotAddedInventory: false,
      };
    case "SUCCESS_ADD_INVENTORY":
      return {
        ...state,
        inventory: action.payload,
        isAddingInventory: false,
        dataAddedInventory: true,
        dataNotAddedInventory: false,
      };
    case "ERROR_ADD_INVENTORY":
      return {
        ...state,
        isAddingInventory: false,
        dataNotAddedInventory: true,
      };

    case "REQUEST_GET_INVENTORY":
      return {
        ...state,
        isFetchingInventoryGetdata: true,
        dataNotAddedInventory: false,
      };
    case "SUCCESS_GET_INVENTORY":
      return {
        ...state,
        inventoryList: action.payload,
        isFetchingInventoryGetdata: false,
        isState: true,
        dataNotAddedInventory: false,
      };
    case "ERROR_GET_INVENTORY":
      return { ...state, isFetchingInventoryGetdata: false };

    // Upload file
    case "REQUEST_UPLOAD_FILE":
      return {
        ...state,
        isFetchingUploadFile: true,
        addUploadFile: false,
        notAdduploadFile: false,
      };
    case "SUCCESS_UPLOAD_FILE":
      return {
        ...state,
        uploadfile: action.payload,
        isFetchingUploadFile: false,
        addUploadFile: true,
        notAdduploadFile: false,
      };
    case "ERROR_UPLOAD_FILE":
      return { ...state, isFetchingUploadFile: false, notAdduploadFile: true };

    case "REQUEST_DELETE_INVENTORY":
      return { ...state };
    case "SUCCESS_DELETE_INVENTORY":
      return {
        ...state,
        inventoryList: state.inventoryList.filter(
          (item) => item.inventoryId !== action.inventoryId
        ),
      };
    case "ERROR_DELETE_INVENTORY":
      return { ...state };

    case "UPDATE_REQUEST_Inventory":
      return { ...state };
    case "UPDATE_SUCCESS_Inventory":
      return {
        ...state,
        inventoryList: state.inventoryList.map(
          (x) =>
            action.payload.find(
              ({ inventoryId }) => inventoryId == x.inventoryId
            ) || x
        ),
      };
    case "UPDATE_ERROR_Inventory":
      return { ...state };
    //Demand
    case "REQUEST_ADD_DEMAND":
      return { ...state, isAddingDemanddata: true, notAddDemandData: false };
    case "SUCCESS_ADD_DEMAND":
      return {
        ...state,
        demandList: action.payload,
        isAddingDemanddata: false,
        notAddDemandData: false,
      };
    case "ERROR_ADD_DEMAND":
      return { ...state, isAddingDemanddata: false, notAddDemandData: true };

    //Attribute
    case "REQUEST_ADD_ATTRIBUTE":
      return {
        ...state,
        isAddingAttribute: false,
        dataAddedAttribute: false,
        dataNotAttribute: false,
      };
    case "SUCCESS_ADD_ATTRIBUTE":
      return {
        ...state,
        attributeList: action.payload,
        isAddingAttribute: false,
        dataAddedAttribute: true,
        dataNotAttribute: false,
      };
    case "ERROR_ADD_ATTRIBUTE":
      return {
        ...state,
        isAddingAttribute: false,
        dataAddedAttribute: false,
        dataNotAttribute: true,
      };
    case "REQUEST_ATTRIBUTE_GET_DATA":
      return {
        ...state,
        isFetchingAttributedata: true,
        dataNotAttribute: false,
      };
    case "SUCCESS_ATTRIBUTE_GET_DATA":
      return {
        ...state,
        attributeList: action.payload,
        isFetchingAttributedata: false,
        dataNotAttribute: false,
      };
    case "ERROR_ATTRIBUTE_GET_DATA":
      return { ...state, isFetchingAttributedata: false };

    case "DELETE_REQUEST_USERS":
      return { ...state };
    case "DELETE_SUCCESS_USERS":
      return {
        ...state,
        attributeList: state.attributeList.filter(
          (item) => item.attributeId !== action.attributeId
        ),
      };
    case "DELETE_ERROR_USERS":
      return { ...state };
    case "UPDATE_REQUEST_USERS":
      return { ...state };
    case "UPDATE_SUCCESS_USERS":
      return {
        ...state,
        attribute: state.attribute.map(
          (x) =>
            action.payload.find(
              ({ attributeId }) => attributeId === x.attributeId
            ) || x
        ),
      };
    case "UPDATE_ERROR_USERS":
      return { ...state };

    case "REQUEST_ATTRIBUTE_BY_ID":
      return { ...state, isFetchingGetAttributeById: true };
    case "SUCCESS_ATTRIBUTE_BY_ID":
      return {
        ...state,
        attributeById: action.payload,
        isFetchingGetAttributeById: false,
      };
    case "ERROR_ATTRIBUTE_BY_ID":
      return { ...state, isFetchingGetAttributeById: false };

    case "REQUEST_DEMAND_QUANTITY":
      return { ...state, isFetchingDemand: true };
    case "SUCCESS_DEMAND_QUANTITY":
      return {
        ...state,
        demandList: action.payload.data,
        isFetchingDemand: false,
      };
    case "ERROR_DEMAND_QUANTITY":
      return { ...state, isFetchingDemand: false };

    case "REQUEST_GET_ALL_INVENTORY":
      return { ...state, isFetchingInventoryAll: true };
    case "SUCCESS_GET_ALL_INVENTORY":
      return {
        ...state,
        allInventory: action.payload,
        isFetchingInventoryAll: false,
      };
    case "ERROR_GET_ALL_INVENTORY":
      return { ...state, isFetchingInventoryAll: false };

    case "REQUEST_GET_PURCHASE":
      return {
        ...state,
        isFetchingPurchaseList: true,
        isAddingPurchase: false,
        notAddPurchase: false,
      };
    case "SUCCESS_GET_PURCHASE":
      return {
        ...state,
        purchaseList1: action.payload.data,
        isFetchingPurchaseList: false,
        isAddingPurchase: false,
        notAddPurchase: false,
      };
    case "ERROR_GET_PURCHASE":
      return { ...state, isFetchingPurchaseList: false };

    case "REQUEST_GET_APPROVE_DEMAND":
      return { ...state, isFetchningApproveDemandList: true };
    case "SUCCESS_GET_APPROVE_DEMAND":
      return {
        ...state,
        approveDemandList: action.payload.data,
        isFetchningApproveDemandList: false,
      };
    case "ERROR_GET_APPROVE_DEMAND":
      return { ...state, isFetchningApproveDemandList: false };

    case "REQUEST_GET_PRODUCT_BY_DEMAND":
      return { ...state, isFetchingProductByDemand: true };
    case "SUCCESS_GET_PRODUCT_BY_DEMAND":
      return {
        ...state,
        porductListDemand: action.payload.data,
        isFetchingProductByDemand: false,
      };
    case "ERROR_GET_PRODUCT_BY_DEMAND":
      return { ...state, isFetchingProductByDemand: false };

    case "REQUEST_ADD_PURCHASE":
      return { ...state, isAddingPurchase: true };
    case "SUCCESS_ADD_PURCHASE":
      return {
        ...state,
        addPurchase: action.payload,
        isAddingPurchase: false,
        notAddPurchase: false,
      };
    case "ERROR_ADD_PURCHASE":
      return { ...state, isAddingPurchase: false, notAddPurchase: true };

    case "REQUEST_GET_APPROVE_PURCHASE":
      return { ...state, isApprovePurchaseListFetching: true };
    case "SUCCESS_GET_APPROVE_PURCHASE":
      return {
        ...state,
        approvePurchaseList: action.payload.data,
        isApprovePurchaseListFetching: false,
      };
    case "ERROR_GET_APPROVE_PURCHASE":
      return { ...state, isApprovePurchaseListFetching: false };

    case "REQUEST_PURCHASE_ID":
      return { ...state, isFetchingPurchaseById: true };
    case "SUCCESS_PURCHASE_ID":
      return {
        ...state,
        purchaseListById: action.payload,
        isFetchingPurchaseById: false,
      };
    case "ERROR_PURCHASE_ID":
      return { ...state, isFetchingPurchaseById: false };
    case "RESET_PURCHASE_REDUCER":
      return { ...state, purchaseListById: [] };

    case "REQUEST_ADD_IGP":
      return { ...state, isAddingIGP: true };
    case "SUCCESS_ADD_IGP":
      return { ...state, IGP: action.payload, isAddingIGP: false };
    case "ERROR_ADD_IGP":
      return { ...state, isAddingIGP: false };

    case "REQUEST_GET_IGP":
      return { ...state, isFetchingIGP: true };
    case "SUCCESS_GET_IGP":
      return { ...state, IGPList: action.payload, isFetchingIGP: false };
    case "ERROR_GET_IGP":
      return { ...state, isFetchingIGP: false };

    case "REQUEST_ADD_ISSUANCE":
      return { ...state, isAddingIssuance: true };
    case "SUCCESS_ADD_ISSUANCE":
      return { ...state, issuance: action.payload, isAddingIssuance: false };
    case "ERROR_ADD_ISSUANCE":
      return { ...state, isAddingIssuance: false };

    case "REQUEST_GET_ISSUANCE":
      return { ...state, isFetchingIssuance: true };
    case "SUCCESS_GET_ISSUANCE":
      return {
        ...state,
        IssuanceList: action.payload.data,
        isFetchingIssuance: false,
      };
    case "ERROR_GET_ISSUANCE":
      return { ...state, isFetchingIssuance: false };

    case "REQUEST_SEARCH_INVENTORY":
      return { ...state, isFetchingSearchInventoryList: true };
    case "SUCCESS_SEARCH_INVENTORY":
      return {
        ...state,
        searchInventoryList: action.payload,
        isFetchingSearchInventoryList: false,
      };
    case "ERROR_SEARCH_INVENTORY":
      return { ...state, isFetchingSearchInventoryList: false };

    case "REQUEST_SEARCH_DEMAND":
      return { ...state, isFetchingSearchDemandList: true };
    case "SUCCESS_SEARCH_DEMAND":
      return {
        ...state,
        searchDemandList: action.payload,
        isFetchingSearchDemandList: false,
      };
    case "ERROR_SEARCH_DEMAND":
      return { ...state, isFetchingSearchDemandList: false };

    case "REQUEST_SEARCH_PURCHASE":
      return { ...state, isFetchingSearchPurchaseList: true };
    case "SUCCESS_SEARCH_PURCHASE":
      return {
        ...state,
        searchPurchaseList: action.payload,
        isFetchingSearchPurchaseList: false,
      };
    case "ERROR_SEARCH_PURCHASE":
      return { ...state, isFetchingSearchPurchaseList: false };

    case "REQUEST_SEARCH_IGP":
      return { ...state, isFetchingSearchIGPList: true };
    case "SUCCESS_SEARCH_IGP":
      return {
        ...state,
        searchIGPList: action.payload,
        isFetchingSearchIGPList: false,
      };
    case "ERROR_SEARCH_IGP":
      return { ...state, isFetchingSearchIGPList: false };

    case "REQUEST_SEARCH_ISSUANCE":
      return { ...state, isFetchingSearchIssuanceList: true };
    case "SUCCESS_SEARCH_ISSUANCE":
      return {
        ...state,
        searchIssuanceList: action.payload,
        isFetchingSearchIssuanceList: false,
      };
    case "ERROR_SEARCH_ISSUANCE":
      return { ...state, isFetchingSearchIssuanceList: false };

    // Select Purchase From Demand

    case "REQUEST_PURCHASE_FROM_DEMAND":
      return { ...state, isFetchingPurchaseFromDemand: true };
    case "SUCCESS_PURCHASE_FROM_DEMAND":
      return {
        ...state,
        purchaseFromDemandData: action.payload.data,
        isFetchingPurchaseFromDemand: false,
      };
    case "ERROR_PURCHASE_FROM_DEMAND":
      return { ...state, isFetchingPurchaseFromDemand: false };

    case "REQUEST_GET_APPROVE_DEMAND_NEW":
      return { ...state, isFetchningApproveDemandListNew: true };
    case "SUCCESS_GET_APPROVE_DEMAND_NEW":
      return {
        ...state,
        approveDemandListNew: action.payload.data,
        isFetchningApproveDemandListNew: false,
      };
    case "ERROR_GET_APPROVE_DEMAND_NEW":
      return { ...state, isFetchningApproveDemandListNew: false };

    case "REQUEST_PURCHASE_FROM_DEMAND_NEW":
      return { ...state, isFetchingPurchaseFromDemandNew: true };
    case "SUCCESS_PURCHASE_FROM_DEMAND_NEW":
      return {
        ...state,
        purchaseFromDemandDataNew: action.payload.data,
        isFetchingPurchaseFromDemandNew: false,
      };
    case "ERROR_PURCHASE_FROM_DEMAND_NEW":
      return { ...state, isFetchingPurchaseFromDemandNew: false };
    //Receiving
    case "REQUEST_GET_RECEIVING":
      return { ...state, isFetchingReceiving: true };
    case "SUCCESS_GET_RECEIVING":
      return {
        ...state,
        ReceivingList: action.payload.data,
        isFetchingReceiving: false,
      };
    case "ERROR_GET_RECEIVING":
      return { ...state, isFetchingReceiving: false };

          //Return get
    case "REQUEST_GET_RETURN":
      return { ...state, isFetchingReturn: true };
    case "SUCCESS_GET_RETURN":
      return {
        ...state,
        returnList: action.payload.data,
        isFetchingReturn: false,
      };
    case "ERROR_GET_RETURN":
      return { ...state, isFetchingReturn: false };

    case "REQUEST_ADD_RECEIVING":
      return { ...state, isAddingReceiving: false };
    case "SUCCESS_ADD_RECEIVING":
      return { ...state, receiving: action.payload, isAddingReceiving: true };
    case "ERROR_ADD_RECEIVING":
      return { ...state, isAddingReceiving: false };

    case "REQUEST_SEARCH_RECEIVING":
      return { ...state, isFetchingSearchReceivingList: true };
    case "SUCCESS_SEARCH_RECEIVING":
      return {
        ...state,
        searchReceivingList: action.payload,
        isFetchingSearchReceivingList: false,
      };
    case "ERROR_SEARCH_RECEIVING":
      return { ...state, isFetchingSearchReceivingList: false };
             //Add accout master
    case "REQUEST_ADD_ACCOUNT_MASTER_CONTROL":
      return { ...state, isSavingAccountMasterControlList: true };
    case "SUCCESS_ADD_ACCOUNT_MASTER_CONTROL":
      return {
        ...state,
        accountMasterControl: action.payload,
        isSavingAccountMasterControlList: false,
      };
    case "ERROR_ADD_ACCOUNT_MASTER_CONTROL":
      return { ...state, isSavingAccountMasterControlList: false };

       //get accout master
    case "REQUEST_GET_ACCOUNT_MASTER_CONTROL":
      return { ...state, isFetchingAccountMasterControlList: true };
    case "SUCCESS_GET_ACCOUNT_MASTER_CONTROL":
      return {
        ...state,
        accountMasterControlList: action.payload,
        isFetchingAccountMasterControlList: false,
      };
    case "ERROR_GET_ACCOUNT_MASTER_CONTROL":
      return { ...state, isFetchingAccountMasterControlList: false };

                 //Add recivable master
    case "REQUEST_ADD_REVIVABLE_ACCOUNT":
      return { ...state, isSavingRecivableAccount: true };
    case "SUCCESS_ADD_REVIVABLE_ACCOUNT":
      return {
        ...state,
        recivableAccount: action.payload,
        isSavingRecivableAccount: false,
      };
    case "ERROR_ADD_REVIVABLE_ACCOUNT":
      return { ...state, isSavingRecivableAccount: false };

       //get accout master
    case "REQUEST_GET_RECIVABLE_ACCOUNT":
      return { ...state, isFetchingRecivableAccount: true };
    case "SUCCESS_GET_RECIVABLE_ACCOUNT":
      return {
        ...state,
        recivableAccountList: action.payload.result,
        isFetchingRecivableAccount: false,
      };
    case "ERROR_GET_RECIVABLE_ACCOUNT":
      return { ...state, isFetchingRecivableAccount: false };

      
                 //Add Level3 master
    case "REQUEST_ADD_LEVEL3_ACCOUNT":
      return { ...state, isSavingLevel3Account: true };
    case "SUCCESS_ADD_LEVEL3_ACCOUNT":
      return {
        ...state,
        level3Account: action.payload.result,
        isSavingLevel3Account: false,
      };
    case "ERROR_ADD_LEVEL3_ACCOUNT":
      return { ...state, isSavingLevel3Account: false };

       //get LEVEL3 accout
    case "REQUEST_GET_LEVEL3_ACCOUNT":
      return { ...state, isFetchingLevel3Account: true };
    case "SUCCESS_GET_LEVEL3_ACCOUNT":
      return {
        ...state,
        level3AccountList: action.payload,
        isFetchingLevel3Account: false,
      };
    case "ERROR_GET_LEVEL3_ACCOUNT":
      return { ...state, isFetchingLevel3Account: false };

      case "REQUEST_GET_PRODUCT_BY_VENDOR_ID":
        return { ...state, isFetchingProductByVendor: true };
      case "SUCCESS_GET_PRODUCT_BY_VENDOR_ID":
        console.log(action.payload,"tttttttttttttttttt")
        return {
          ...state,
          productByVendorList: action.payload.data,
          isFetchingProductByVendor: false,
        };
      case "ERROR_GET_PRODUCT_BY_VENDOR_ID":
        return { ...state, isFetchingProductByVendor: false };
// master
        case "REQUEST_ADD_INV_MASTER_CONTROL":
          return { ...state, isAddingInvMasterControl: true };
        case "SUCCESS_ADD_INV_MASTER_CONTROL":
          return {
            ...state,
            isAddingInvMasterControl: false,
          };
        case "ERROR_ADD_INV_MASTER_CONTROL":
          return { ...state, isAddingInvMasterControl: false };

          case "REQUEST_GET_INV_MASTER_CONTROL":
            return { ...state, isFetchingInvMasterControl: true };
          case "SUCCESS_GET_INV_MASTER_CONTROL":
            return {
              ...state,
              invMasterControlList: action.payload,
              isFetchingInvMasterControl: false,
            };
          case "ERROR_GET_INV_MASTER_CONTROL":
            return { ...state, isFetchingInvMasterControl: false };
            case "REQUEST_GET_INV_SIZE":
              return { ...state, isFetchingInvSize: true };
            case "SUCCESS_GET_INV_SIZE":
              return {
                ...state,
                invSizeList: action.payload,
                isFetchingInvSize: false,
              };
            case "ERROR_GET_INV_SIZE":
              return { ...state, isFetchingInvSize: false };
              case "REQUEST_GET_INV_MODAL":
                return { ...state, isFetchingInvModal: true };
              case "SUCCESS_GET_INV_MODAL":
                return {
                  ...state,
                  invModalList: action.payload,
                  isFetchingInvModal: false,
                };
              case "ERROR_GET_INV_MODAL":
                return { ...state, isFetchingInvModal: false };

                case "REQUEST_GET_RECEIVING_BYID":
                  return { ...state, isFetchingReceivingById: true };
                case "SUCCESS_GET_RECEIVING_BYID":
                  console.log(action.payload,"tttttttttttttttttt")
                  return {
                    ...state,
                    receivingById: action.payload.data,
                    isFetchingReceivingById: false,
                  };
                case "ERROR_GET_RECEIVING_BYID":
                  return { ...state, isFetchingReceivingById: false };

                  case "SET_EDIT_FIELDS":
                    let valueEmp = state.receivingById[0];    
                    let keyEmp = action.payload.key;
                    valueEmp[keyEmp] = action.payload.value;
                    let valueEmp2 = [valueEmp];
                    return { ...state, receivingById: valueEmp2 };
    default:
      return state;
  }
};
export default AddDataReducer;
